<template lang="pug">
    div.pa-10
        PageTitle(label="Entregadores")

        div(v-acl.cant.hide='`user.list`') Você não possui a permissão necessária para acessar o recurso
        div(v-acl.can.hide='`user.list`')
            v-data-table(
                fixed-header
                :headers="headers"
                :items="couriers.data"
                :items-per-page="couriers.per_page"
                class="elevation-1"
                :loading='couriers.data.length <= 0'
                loading-text="Carregando... Aguarde!"
                hide-default-footer
            )

                template(v-slot:item.actions="{ item }")      
                    div(style="white-space:nowrap")          
                        
                        v-icon(
                            small
                            class="mr-2"
                            @click="editItem(item)"
                            title="Editar"
                        ) mdi-pencil

            paginate( :data="couriers" )
</template>

<script>
export default {
    name: "Couriers",
    components: {
        paginate: () => import( '@/components/Paginate' ),
        PageTitle: () => import( '@/components/PageTitle' ),
    },
    data: function () {
        return {
            params: {},
            headers: [
                {
                    text: '#',
                    value: 'id',
                    sortable: false,
                    width: '1%'
                },
                {   text: 'Nome', value: 'fullname', sortable: false  },
                {   text: '', value: 'actions', sortable: false, width: '1%' },
            ],
            couriers: {
                data: []
            }
        }
    },
    created: function() {
        this.params = {...this.$route.query}// clone prevent bug
        this.loadData()
    },
    methods: {
        loadData: async function(){
            if ( this.acl('courier.edit') ) {
                let res = await this.api.get( 'couriers', this.params )
                this.couriers = res.data
            }
        },
        
        editItem: function( item ){
            this.$router.push( { name: 'couriers-edit', params: { id: item.id } })
        },
    },
}
</script>
